import $ from 'jquery';
// import {TweenMax} from "gsap";
import _            from 'lodash';
import slick from 'slick-carousel';
import {TweenMax} from 'gsap';
import ScrollMagic from 'scrollmagic';
import 'scrollmagic/scrollmagic/minified/plugins/animation.gsap.min.js';
// import ScrollMagic from 'scrollmagic';
// require('gsap/src/minified/plugins/ScrollToPlugin.min.js');
// import 'ScrollMagic/scrollmagic/minified/plugins/animation.gsap.min.js';
import Masonry from 'masonry-layout';
// import 'ScrollMagic/scrollmagic/minified/plugins/debug.addIndicators.min.js';

var $body = $('body'),
	breakpoints = {
		'init': 0,
		'xxs': 480,
		'xs': 540,
		'sm': 640,
		'md': 768,
		'xmd': 968,
		'lg': 1024,
		'xl': 1280,
		'xxl': 1440,
	},
	controller = new ScrollMagic.Controller();

hamburger('#hamburger', '.header');
parallaxElement('.js-parallax-trigger');

function hamburger(hamburger, nav) {
	var $nav = $(nav),
		$hamburger = $(hamburger),
		$lineOne = $hamburger.find('span:first-child'),
		$lineTwo = $hamburger.find('span:nth-child(2)'),
		$lineThree = $hamburger.find('span:last-child'),
		hamburgerAnimation = new TimelineMax({paused: true}),
		canClick = 1;

	hamburgerAnimation
		.to($lineOne, 	0.3, {top: "50%"},    'start')
		.to($lineThree, 0.3, {top: "50%"}, 	  'start')
		.set($lineTwo, 		 {autoAlpha: 1},  'start')
		.set($lineTwo,       {autoAlpha: 0},  'mid')
		.to($lineOne,	0.3, {rotation: 45},  'end')
		.to($lineThree, 0.3, {rotation: -45}, 'end');

	$hamburger.on('click', function(){
		if (canClick) {
			canClick = 0;
			if (!$hamburger.hasClass('open')) {
				hamburgerAnimation.restart();
				$hamburger.addClass('open');
				$nav.addClass('open');
				$body.addClass('noScrolling');
			}else {
				hamburgerAnimation.reverse();
				$hamburger.removeClass('open');
				$nav.removeClass('open');
				$body.removeClass('noScrolling');
			}
			setTimeout(function(){
				canClick = 1;
			}, 500);
		}
	})
} 

let grid;
let msnry;

if ($('.js-masonry').length) {
	grid = document.querySelector('.js-masonry');

	msnry = new Masonry(grid, {
	  itemSelector: '.column',
	  columnWidth: '.column',
	})
}

function parallaxElement(element) {
	$(element).each(function(){
		var $this = $(this),
			targetID = $this.data('parallax-target'),
			$target = $('[data-parallax-id="'+targetID+'"]'),
			parallax = new TimelineMax(),
			parallaxIntensity = $this.data('parallax') / 100 * 75;

		parallax.to($target, 1, {yPercent: 30});
		var parallaxScene = new ScrollMagic.Scene({triggerElement: this, triggerHook: 0.075, duration: "150%"})
	            .setTween(parallax)
	            // .addIndicators('tween', 'element')
	            .addTo(controller);
	})
}

$('.js-slider-block').each(function(){
	var $this = $(this),
		$slider = $this.find('.js-slider'),
		$nextBtn = $this.find('.js-slick-next'),
		$prevBtn = $this.find('.js-slick-prev'),
		bp = $slider.data('bp');

	// return;

	if ($slider) {
		if (bp) {
			slickBP($slider, bp);
		}else {
			if ($slider.hasClass('js-fade')) {
				slickSimple($slider, true);
			}else{
				slickSimple($slider, false);
			}
		}
	};

	if ($slider && $nextBtn) {
		$nextBtn.on('click', function(){
			$slider.slick("slickNext");
		})
	}
	if ($slider && $prevBtn) {
		$prevBtn.on('click', function(){
			$slider.slick("slickPrev");
		})
	}
});

function slickSimple($slider, fade){
	$slider.slick({
		dots: true,
		arrows: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		mobileFirst: true,
		responsive: [
		{
	    	breakpoint: breakpoints.xmd,
   			settings: {
				fade: fade,
				speed: 500,
				dots: false,
				autoplay: true, 
				autoplaySpeed : 10000,
				pauseOnHover: true,
    			// speed    	  : 1,
        		// slidesToShow: bp[1],
				// slidesToScroll: bp[1],
			}
        }]
	});
}

function slickBP($slider, bp){
	$slider.slick({
		dots: false,
		arrows: false,
		infinite: true,
		slidesToShow: parseInt(bp[0]),
		// slidesToScroll: bp[0],
		slidesToScroll: 1,
		mobileFirst: true,
		responsive: [
		{
	    	breakpoint: breakpoints.xxs,
   			settings: {
        		slidesToShow: parseInt(bp[1]),
				// slidesToScroll: parseInt(bp[1]),
			}
        },
        {
	    	breakpoint: breakpoints.xmd,
    		settings: {
        		slidesToShow: parseInt(bp[2]),
				// slidesToScroll: parseInt(bp[2]),
			}
	    },
	    {
		    breakpoint: breakpoints.xl,
	    	settings: {
	        	slidesToShow: parseInt(bp[3]),
				// slidesToScroll: parseInt(bp[3]),
		    }
		}]
	})
}

function videoTile(element) {
	$(element).each(function(){
		var $this = $(this);

		$this.on('click', function(){
			var src = $this.data('video-src'),
				id = $this.data('video-id');

			if (src && id) {
				if (src == 'youtube' || src == 'YouTube' || src == 'Youtube') {
					$this.unbind('click');
					$this.append(`<iframe src="https://www.youtube.com/embed/${id}?modestbranding=1&rel=0&autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>`);
				}else if (src == 'Vimeo' || src == 'vimeo') {
					$this.unbind('click');
					$this.append(`<iframe src="https://player.vimeo.com/video/${id}?autoplay=1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`);
				}else {
					console.log('video source does not match youtube or vimeo');
					return;
				}
			}
		})
	})
}

videoTile('.js-video-tile');

function staggerIn($element) {
	let staggerIn = new TimelineMax();

	staggerIn.staggerTo($element, 0.5, {autoAlpha: 1}, 0.2, 0.2);
}

function staggerOut($element) {
	let staggerOut = new TimelineMax();

	staggerOut.staggerTo($element, 0.5, {autoAlpha: 0}, 0.2, 0.2);
}

// $('js-stagger-in-all').each(function(){
// 	let parallaxScene = new ScrollMagic.Scene({triggerElement: this, triggerHook: 0.075});

// 	parallaxScene
//         .on('enter', function(){
// 			staggerIn($(this).find('>*'));
//         })
// 	    .addTo(controller);
// });



$('.js-filters-block').each(function(){
	var $filterBlock = $(this),
		$filter = $filterBlock.find('[data-filter-link]'),
		$filterTarget = $filterBlock.find('[data-filter-target]'),
		$filterContent = $filterBlock.find('.js-filters-content');

	$filter.on('click', function(e){
		e.preventDefault();
		let $this = $(this);
		let filter = $this.data('filter-link').replace(/ /g, '.').split(',');
		let $target;

		console.log(filter);

		// if (filter.split(' ').length >= 2) {

		// }
		// // console.log($('.PARTNERS').length);
		// console.log(filter.join('.'));
		// console.log($(`.js-filters-content .${filter.join('.')}`));
		// return;
		$('[data-filter-target].js-active').removeClass('js-active');
		$('.js-accordion-grid-content').slideUp(function(){
			$(this).remove();
		});

		if (filter != 'All') {
			$target = $(`.js-filters-content .${filter.join(', .js-filters-content ')}`);
			// console.
			console.log(`.js-filters-content .${filter.join(', .js-filters-content ')}`);
		} else {
			$target = $filterBlock.find('[data-filter-target]');
		}

		if (!$this.hasClass('active')) {
			$filter.removeClass('active');
			// tweenHeight($filterContent, 0);
			TweenLite.to($filterTarget, 0.3, {autoAlpha: 0});
			$filterContent.height($filterContent.height());
			setTimeout(function(){
				TweenLite.set($filterTarget, {display: 'none'});
				TweenLite.set($target, {display: 'block', autoAlpha: 0});
				TweenLite.to($target, 0.3, {autoAlpha: 1});
				tweenHeight($filterContent);
			}, 300)
			// $filterTarget.fadeOut(300, function(){
			// 	// $filterBlock.find('[data-filter-target]').hide();
			// 	$target.show();
			// });
			$this.addClass('active');
		}

	})
})

function expansion($parent, $target){
	$('.js-expander-watch').slideUp();
	if ($parent.hasClass('js-expander-open')) {
		$('.js-expander-open').removeClass('js-expander-open');
		$target.slideUp();
	}else{
		$('.js-expander-open').removeClass('js-expander-open');
		$parent.addClass('js-expander-open');
		$target.slideDown();
	};
}

function expander(parent) {
	$(parent).each(function(){
		var $parent = $(this),
			bp = $parent.data('expander'),
			$trigger = $parent.find('.js-expander-trigger'),
			$target = $parent.find('.js-expander-watch').first(),
			canClick = 1;

		$trigger.on('click', function(e){
			e.preventDefault();
			if (canClick) {
				canClick = 0;
	    		if (bp == undefined || bp == '' || bp == null) {
	    			expansion($parent, $target);
	    		}else {
	    			if ($(window).width() < breakpoints[bp]) {
	    				expansion($parent, $target);
	    			};
	    		}
				setTimeout(function(){
					canClick = 1;
				}, 400);
			}
	    });    		
   	});
}

expander('.js-expander');

if ($('.js-map').length) {
	$.ajax({
	    url     : `https://maps.googleapis.com/maps/api/js?key=${$('.js-map').data('map-api')}`,
	    dataType: "script",
	    success : googleMap
	});
}

function googleMap() {
    $('.js-map').each(function () {
        let lngLat  = { lng: $(this).data('lng'), lat: $(this).data('lat') };
        let zoom = $(this).data('zoom');
            // lat = $(this).data('lat'),
        let thisMap = new google.maps.Map(this, {
                center          : lngLat,
                zoom            : zoom,
                disableDefaultUI: true,
                styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f3f3f3"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"geometry.fill","stylers":[{"visibility":"on"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#b5d1dc"},{"visibility":"on"}]}]
            });

        let marker = new google.maps.Marker({
            position: lngLat,
            map     : thisMap
            // title: 'Hello World!'
        });
    })
}


/*------------------------------------------------------------------
 Tabs
 ------------------------------------------------------------------*/

function tabs(tabs){
	$(tabs).each(function(){
		let $this = $(this);
		let $links = $this.find('[data-tabs-link]');
		let $panels = $this.find('[data-tabs-panel]');

		let $activePanel = $this.find('[data-tabs-panel]:first-child');

		$activePanel.css('display', 'block');

		$links.on('click', function(e){
			e.preventDefault();
			let $thisLink = $(this);
			let $targetPanel = $(`[data-tabs-panel="${$thisLink.data('tabs-link')}"]`);
			if (!$thisLink.hasClass('js-active')) {
				tweenSiblings($('[data-tabs-panel].js-active'), $targetPanel);
				$links.removeClass('js-active');
				$panels.removeClass('js-active');
				$thisLink.addClass('js-active');
				$targetPanel.addClass('js-active');
			}
		})
	});
}

tabs('[data-tabs]');


/*------------------------------------------------------------------
 Sticky Header
 ------------------------------------------------------------------*/

let $header  = $('header');

const fixedHeader = (scrollOffset = 10) => {
    ($(window).scrollTop() >= scrollOffset && $header.length) ? $header.addClass('fixed') : $header.removeClass('fixed');
};

fixedHeader();

$(window).scroll(() => fixedHeader());

/*------------------------------------------------------------------
 Tween Height
 ------------------------------------------------------------------*/

function tweenSiblings($from, $to) {
    let tweenSiblings = new TimelineMax();
    let currentHeight;
    let newHeight;

    currentHeight = $from.height();

    $to.css({
        'display':'block',
        'visibility':'hidden'
    });
    
    newHeight = $to.height();

    $to.css({
        'display':'none',
        'visibility':'visible'
    });

    tweenSiblings
        .fromTo($from, 0.3, {autoAlpha: 1, height: currentHeight}, {autoAlpha: 0, height: newHeight}, 'start')
        .set($to, {display: 'block', position: 'absolute', autoAlpha: 0}, 'start')
        .to($from, 0.3, {autoAlpha: 0}, {autoAlpha: 1, height: newHeight}, 'mid')
        .set($from, {position: 'absolute'}, 'mid')
        .set($to, {position: 'relative'}, 'mid')
        .to($to, 0.3, {autoAlpha: 1}, 'mid')
        .set($from, {display: 'none', height: 'auto'}, 'end'); 
}

function tweenHeight($element, desiredHeight = 'auto') {
	let additional = parseInt($element.css('padding-top')) + parseInt($element.css('padding-bottom'));
	let currentHeight = $element.height();
	let newHeight;

	// console.log(currentHeight);

	$element.css('height', desiredHeight);
	newHeight = $element.height();
	$element.css('height', currentHeight);

	TweenLite.to($element, 0.5, {height: newHeight + additional});
	setTimeout(function(){
		$element.css('height', desiredHeight);
	}, 500)
}



/*------------------------------------------------------------------
 Equalizer
 ------------------------------------------------------------------*/


function equalize($parent) {
	$parent.each(function(){
		let currentHeight = 0;
		let $this = $(this);
		let $target;

		if ($this.data('equalize') != undefined) {
			$target = $this.find(`[data-equalize-watch="${$this.data('equalize')}"]`);
		}else {
			$target = $this.find(`[data-equalize-watch]`);
		}

		$target.css('height', 'auto').addClass('clearfix');

		for (var i = 0; i < $target.length; i++) {
			if ($target.eq(i).height() >= currentHeight) {
				currentHeight = $target.eq(i).height();
			}
		}
		$target.height(currentHeight);
	})
}

$(window).on('resize', _.debounce(function(){
	equalize($('[data-equalize]'));
}, 100));

equalize($('[data-equalize]'));


// (function($){

//    $.fn.equalize = function(options) {
//       	var settings = {}

// 		this.construct = function(options){
// 			$.extend(settings , options);
// 		}

// 		this.construct(options);

// 		this.equalize = function(){
// 			let currentHeight = 0;
// 			let $this = $(this);
// 			let $target;

// 			if ($this.data('equalize') != undefined) {
// 				$target = $this.find(`[data-equalize-watch="${$this.data('equalize')}"]`);
// 			}else {
// 				$target = $this.find(`[data-equalize-watch]`);
// 			}

// 			$target.css('height', 'auto').addClass('clearfix');

// 			for (var i = 0; i < $target.length; i++) {
// 				if ($target.eq(i).height() >= currentHeight) {
// 					currentHeight = $target.eq(i).height();
// 				}
// 			}
// 			$target.height(currentHeight);
// 		}

// 		// $(window).on('resize', function(){
// 		// 	this.equalize();
// 		// });

// 		this.equalize();

//    }; 

// })($);

// $('[data-equalize]').equalize();

/*------------------------------------------------------------------
 Infinite Scrolling Slider
 ------------------------------------------------------------------*/

 let $infiniteSlider = $('.js-infinite-slide');

$infiniteSlider.each(function(){
  let $this = $(this);
  // Apply breakpoint classes to .js-slider
  // Works using class names `bp-up-i` so `sm-up-4` outputs 4 slides to be shown on small screens upwards
  let breakpoints = {init: 0,xxs: 480,xs: 540,sm: 640,md: 768,xmd: 968,lg: 1024,xl: 1280,xxl: 1440};
  let responsiveBreakpoints = [];
  let classBreakpoints = $this[0].className.split(' ');
  let baseSpeed = 500; //ms

  classBreakpoints.forEach(function (value) {
    if(value.indexOf(`-up-`) > -1){
      let breakpoint = value.split('-')[0];
      let slides = parseInt(value.split('-').pop().trim());
      responsiveBreakpoints.push({breakpoint: breakpoints[breakpoint],settings: {slidesToShow: slides, speed: baseSpeed*slides}});
    }
  });

  $this.slick({
    // Options
    dots          : false,
    arrows    	  : false,
    infinite      : true,
    mobileFirst   : true,
    draggable     : false,
    swipe         : false,
    autoplay	  : true,
    autoplaySpeed : 0,
    speed    	  : baseSpeed,
    cssEase		  : 'linear',
    responsive    : responsiveBreakpoints
  });

});


/*------------------------------------------------------------------
 ajax filters
 ------------------------------------------------------------------*/

//  function getAllFilters(item) {
// 	let filter = []
// 	$('.js-select').each(function(){
// 		let currentFilter = $(this).find(item).data('value');
// 		if (currentFilter != undefined) {
// 			filter.push(currentFilter);
// 		}
// 	});
// 	return filter.join("+")
// }

function checkForMore(data, $loadMoreElement){
	if (data == false) {
		$loadMoreElement.hide();
	}else {
		$loadMoreElement.show();
	}
}

function ajaxResponse(ajaxUrl, $target) {
	$.ajax({
	  	dataType: "json",
	  	url: ajaxUrl,
	  	success: function(response){
	  		let responseArray = response.data.items;
	  		let offset = currentCount($('.js-masonry .column'));
	  		$target.html('');

	  		TweenLite.to(window, 1.5, {scrollTo: {y:$target.parents('section').offset().top - $header.height(), autoKill:false}, ease:Power1.easeInOut});

	  		if (responseArray.length) {
		  		for (var i = 0; i < responseArray.length; i++) {
		  			$target.append(responseArray[i].html);
		  		}
		  		checkForMore(response.data.show_more, $('.js-ajax-loadMoreWrap'));
		  		equalize($('[data-equalize]'));
		  		if ($('.js-masonry').length) {
		  			masonryReset(0, '.js-masonry .column');
		  		}
	  		} else {
	  			$target.append(`<h3 class="text-center">Sorry, there are no results to display.</h3>`);
	  		}
	  	}
	});
}

function ajaxLoad(ajaxUrl, $target) {

	$.ajax({
	  	dataType: "json",
	  	url: ajaxUrl,
	  	success: function(response){
	  		let responseArray = response.data.items;
	  		let offset = currentCount($('.js-masonry .column'));
	  		for (var i = 0; i < responseArray.length; i++) {
	  			$target.append(responseArray[i].html);
	  		}
	  		checkForMore(response.data.show_more, $('.js-ajax-loadMoreWrap'));
	  		equalize($('[data-equalize]'));
	  		if ($('.js-masonry').length) {
	  			masonryReset(offset, '.js-masonry .column');
	  		}
	  	}
	});
}

let $ajaxFilter = $('.js-ajax-filter');
let $ajaxSearch = $('.js-ajax-search');
let $ajaxContent = $('.js-ajax-content');
let $ajaxTitle = $('.js-ajax-title');
let $ajaxLoad = $('.js-ajax-loadMore');
let searchTimer = null;

$ajaxFilter.each(function(){
	let $this = $(this);
	let filterID = $this.data('value');
	let apiUrl = $this.data('api-url');
	let searchTerms;
	let ajaxUrl;

	$this.on('click', function(e){
		e.preventDefault();

		if ($ajaxSearch.length) {
			searchTerms = getInputValue($ajaxSearch).replace(/\s+/g, '-').toLowerCase();
		}
		if ($ajaxTitle.length) {
			$ajaxTitle.text($(this).text());
		}

		ajaxUrl = `${apiUrl}?filter_categories=${filterID}&search=${searchTerms}`;
		moveClass($ajaxFilter, $this, 'active');

		ajaxResponse(ajaxUrl, $('.js-ajax-target'));
	});

});

$ajaxSearch.on('input', function(){
	let $this = $(this);
	let apiUrl = $this.data('api-url');
	let filterID = $('.js-ajax-filter.active').data('value');
	let searchTerms = getInputValue($ajaxSearch).replace(/\s+/g, '-').toLowerCase();
	let ajaxUrl = `${apiUrl}?filter_categories=${filterID}&search=${searchTerms}`;
	let valueLength = getInputValue($this).length; 

	if (searchTimer) {
	    clearTimeout(searchTimer); //cancel the previous timer.
	    searchTimer = null;
	}

	searchTimer = setTimeout(function(){
		if (valueLength >= 3 && valueLength != 0) {
			ajaxResponse(ajaxUrl, $('.js-ajax-target'));
		}else {
			ajaxUrl = `${apiUrl}?filter_categories=${filterID}&search=`;
			ajaxResponse(ajaxUrl, $('.js-ajax-target'));
		}
	}, 500);

});

$ajaxLoad.on('click', function(e){
	e.preventDefault();
	let $this = $(this);
	let apiUrl = $this.data('api-url');
	// let filterID = $('.js-ajax-filter.active').data('value');
	let offset = currentCount('.js-ajax-content');
	let searchTerms;
	let filterID;
	let ajaxUrl;

	// console.log($('.js-ajax-filter.active').data('value'));

	// if ($('.js-ajax-filter.active').data('value') != undefined) {
	// 	filterID = $('.js-ajax-filter.active').data('value');
	// }

	filterID = $('.js-ajax-filter.active').data('value');

	if ($ajaxSearch.length) {
		searchTerms = getInputValue($ajaxSearch).replace(/\s+/g, '-').toLowerCase();
	}

	// if (filterID != null || searchTerms.length) {
	// } else {
	// 	ajaxUrl = `${apiUrl}?offset=${offset}`;

	if ($this.hasClass('params')) {
		ajaxUrl = `${apiUrl}&offset=${offset}`;		
	}else {
		ajaxUrl = `${apiUrl}?filter_categories=${filterID}&search=${searchTerms}&offset=${offset}`;
	}
	// }

	// console.log(ajaxUrl);

	ajaxLoad(ajaxUrl, $('.js-ajax-target'));
});



let $ajaxImages = $('.js-ajax-img');

$(document).ready(function() {
	ajaxImageLoad($ajaxImages.eq(0));
});

function ajaxImageLoad($image, i = 0){
    if (i < $ajaxImages.length) {
        let src = $image.data('ajax-url');
    	let $img = $(`<img/>`);

    	if (src != undefined) {
    		$img.attr('src',  src);
	        $img.on('load', function(){
	        	$image.css({
	        		'background-image': `url('${src}')`
	        	});
	        	i++;
        		ajaxImageLoad($ajaxImages.eq(i), i);
	        });
    	}else {
    		console.warn(`image ${i} not found at URL "${src}"`);
    		i++;
	        ajaxImageLoad($ajaxImages.eq(i), i);
    	}

    }
}


function accordionGrid($element) {
	$element.each(function(){
		let $this = $(this);

		$this.on('click', function(e){

			if (!$this.hasClass('PARTNER')) {
				e.preventDefault();

				if (!$this.hasClass('js-active')) {
					$('.js-accordion-grid-content').slideUp(function(){
						$(this).remove();
					});
					$('.js-filters-content [data-filter-target]').removeClass('js-active');
					$this.addClass('js-active');

					let $target = lastInRow($element, $this, $this.index());
					let ID = $this.data('value');
					let templateUrl = `${$this.data('ajax-url')}?ID=${ID}`;
					$.ajax({
			            url: templateUrl,
			            success: function (response) {
			            	let $template = JSON.parse(response).data.item[0].html;

			                $target.after($template);

			                console.log($target);

			                TweenLite.to(window, 1.5, {scrollTo: {y:$this.offset().top, autoKill:false}, ease:Power1.easeInOut});

			                $target.next().css({
			                	'display': 'none'
			                }).slideDown();
			            }
			        });
				}
			}
		})
	})
}

accordionGrid($('.js-filters-content [data-filter-target]'));



/*------------------------------------------------------------------
 generic helpers
 ------------------------------------------------------------------*/

if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
	$('.justified-centered').addClass('safari');
}

function masonryReset(offset, element) {
	let elementArray = [];
	for (var i = offset; i < $(element).length; i++) {
		elementArray.push($(element).eq(i)[0]);
	}
	msnry.appended(elementArray);
	msnry.layout();
}

function getInputValue($input) {
	return $input.val();
}

function moveClass($from, $to, myClass) {
	$from.removeClass(myClass);
	$to.addClass(myClass);
}

function currentCount(element){
	return $(element).length;
}

function lastInRow($items, $el, index) {
    let offsetTop = $el.offset().top;
    let item      = $el;
    $items.slice(index).each(function () {
        if ($(this).offset().top > offsetTop) {
            return false;
        } else {
            item = $(this);
        }
    });
    return item;
}
